import React,{useEffect, useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../components/styles.css";
import { Autoplay, Pagination, Mousewheel} from "swiper";
import { Typography, Button, Box, Modal, TextField, Snackbar, Alert } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import EnquireModal from "./EnquireModal";
import { subheading, buttonStyle } from "../../theme/theme";
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";

const heading = {
    marginTop:"20px",
    textTransform: 'uppercase',
    maxWidth: '900px',
    fontSize: { xs: '27px', md: '80px' },
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '700',
    color: 'rgb(255, 255, 255)',
}
const textfield = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    border: "0.5px solid #e6e6e6",
    mb: "10px",
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: "2px solid #e1d3bc",
    boxShadow: 24,
    p: 3,
  };
  const CloseIconStyle = {
     position: "absolute",
    top: "5px",
    right:"3px",
    cursor:"pointer",
    '&:hover': {
      color: "red",
      backgroundColor:"#4c4f54"
  } 
   }

AOS.init();

function ProjectSubPageHeroSlider({projectDetails}) {
  const [brochureUrl, setBrochureUrl] = useState('');
const [ projectTitle, setProjectTitle ] = useState('')

      useEffect(() => {
        AOS.init();
    
        const addPlanArray = projectDetails.addPlan;
        const projectTitle = projectDetails.project.title;
        setProjectTitle(projectTitle);
        const brochurePdfItems = addPlanArray.filter(item => item.type === 'Brochure Pdf');
        if (brochurePdfItems.length > 0) {
          setBrochureUrl(brochurePdfItems[0].plan_url);
        }
      }, [projectDetails]);
    return (
        <>
           <Swiper direction={"vertical"}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                // autoplay={false}
                loop={true}
                speed={2000}
                pagination={{ clickable: true }}
                mousewheel={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper">
                {projectDetails.subBanner.map((banner, index) => (
                    <SwiperSlide key={index} className="swiperItem">
                        <Box gutterBottom component="div" sx={{ ...heading, display: "flex", flexDirection: "column", justifyContent: "center" }} className="" >
                            <span style={{ marginBottom: "-5px" }}>{banner.banner_title}</span>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                        <ModalForm brochureUrl={brochureUrl} projectTitle={projectTitle} />
                        <EnquireModal projectTitle={projectTitle} />
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default ProjectSubPageHeroSlider;
// modal form for download brochure

export const ModalForm = ({ brochureUrl, projectTitle }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    page_name: "single_project_page",
    agentName: "",
    agentEmail: "sales@cityluxedxb.com ",
    button_type: "enquiry form"
  });

  const handleMobileNumberChange = (value) => {
    let sanitizedValue = value.replace(/\D/g, "");
    if (sanitizedValue && sanitizedValue[0] !== "+") {
      sanitizedValue = `+${sanitizedValue}`;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: sanitizedValue,
    }));
  };

  const sendFormDataToAPI = () => {
    const dataToSend = {
      ...formData,
      page_link: `https://cityluxedxb.com/projects/single-project-details/${projectTitle}`
    };

    fetch('/cityluxedxb/contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then(response => {
        if (response.ok) {
          triggerBrochureDownload();
          setSnackbarMessage("Thank you for downloading the brochure. We will contact you shortly.");
          setSnackbarOpen(true);    
            } else {
          console.error('Failed to send form data');
        }
      })
      .catch(error => {
        console.error('Error sending form data:', error);
      });
  };

  const handleSubmitClick = () => {
    if (!isFormValid()) {
      setSnackbarMessage("Please fill all input fields first.");
          setSnackbarOpen(true);
      return;
    }

    sendFormDataToAPI();
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      formData.phone
      // formData.message
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClick = () => {
    handleClose();
    handleSubmitClick();
  };

  const triggerBrochureDownload = () => {
    const link = document.createElement('a');
    link.href = brochureUrl;
    link.download = 'Brochure.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

    return (
      <div>
          <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
        <Button variant="contained" sx={buttonStyle} onClick={handleOpen}>
          Download Brochure
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`animate__animated ${open ? 'animate__fadeIn' : 'animate__fadeOut'}`}
        >
          <Box sx={style}>
            <CloseIcon sx={CloseIconStyle} onClick={handleClose} />
            <Typography variant="h5" component="h2" sx={{ ...subheading, display: "flex", justifyContent: "center", mt: 1 }}>
              Download Brochure
            </Typography>
            <TextField size="small" name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter Name" id='nameTextField' sx={{ ...textfield, width: "100%" }} />
            <TextField size="small" type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter Email" id='emailTextField' sx={{ ...textfield, width: "100%" }}/>
            <PhoneInput country={"ae"} value={formData.phone} id="phoneNumberTextField"
            onChange={handleMobileNumberChange}
            countryCodeEditable={false}
            inputProps={{
              name: "phone",
              required: true,
              style: {
                width: "inherit",
                height: "auto",
                borderRadius: "0px",
                border: "1px solid #e3e0e0",
              },
            }}
          />
            <Button variant="contained" color="primary" fullWidth sx={buttonStyle} onClick={handleClick}>
             Download Brochure
            </Button>
            <Typography variant="body2" sx={{ fontSize: "10px", padding: "2px", marginTop: "10px" }}>
              We value your privacy. Your contact information is never shared with any third party and will remain internal where you can opt out at any time.
            </Typography>
          </Box>
        </Modal>
      
      </div>
    );
  };
  
