import { useState, useEffect } from "react";
import Client from "../axios";
import useKeywordData from "../../pages/Projects/useKeywordData";
const InputSearchKeywordList = () => {
  const projectsKeyword = useKeywordData();
  const [data, setData] = useState(null);

  useEffect(() => {
    Client.post("https://cityluxedxb.com/cityluxedxb/input_search_keyword.php")
      .then((result) => {
        if (result.data.errorcode === "200") {
          const list = result.data.result || {}; // Ensure list exists

        //   console.log("API Response List:", list); // Debugging log

          // Ensure each property exists and is an array before mapping
          let ad_type = Array.isArray(list.ad_type)
            ? list.ad_type.map((value) => ({ label: value }))
            : [];
          let unit_type = Array.isArray(list.unit_type)
            ? list.unit_type.map((value) => ({ label: value }))
            : [];
          let community = Array.isArray(list.community)
            ? list.community.map((value) => ({ label: value }))
            : [];
          let property_name = Array.isArray(list.property_name)
            ? list.property_name.map((value) => ({ label: value }))
            : [];
          let emirate = Array.isArray(list.emirate)
            ? list.emirate.map((value) => ({ label: value }))
            : [];

          setData({
            unit_type,
            ad_type,
            community,
            property_name,
            emirate,
          });
        } else {
          console.error("API Error:", result.data.message);
          setData(null);
        }
      })
      .catch((err) => {
        console.error(`Input search keyword component error: ${err}`);
      });
  }, []);

  return [data];
};

export default InputSearchKeywordList;
