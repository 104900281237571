import React, { useState, useEffect, useContext } from 'react';
import ProjectsCards from './ProjectsCards';
import { Grid, Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
import { Link } from "react-router-dom";
import axios from 'axios';
import Heading from '../../components/heading';
import { FaCircleArrowUp } from "react-icons/fa6";
import UserContextCurrency from '../../context/currencyChange';
import { numberWithCommas } from '../../components/subcomponent/global_functions';
import { useProjectDetails } from './ProjectDetailsContext';
import { useNavigate } from 'react-router-dom';
import { useDeveloperContext } from './DeveloperContext';
import { useCommunityContext } from './CommunityContext';

const OtherProjectsBySameDeveloper = ({ projectDetails }) => {
  const navigate = useNavigate();
  const { setDeveloperInfo } = useDeveloperContext();
  const { setCommunityInfo } = useCommunityContext();
  const [project, setProjects] = useState([]);
  const [byCommunity, setByCommunity] = useState([]);
  const { setProjectDetails } = useProjectDetails();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const formData = new FormData();
        formData.append('id', projectDetails.project.id);
        formData.append('developer_name', projectDetails.project.developer_name);
        formData.append('community', projectDetails.project.community);
        const response = await axios.post('https://cityluxedxb.com/cityluxedxb/matchProjectsdetails.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // console.warn("Response data", response.data.byDeveloper);

        if (response.data && Array.isArray(response.data.byDeveloper)) {
          setProjects(response.data.byDeveloper);
          setByCommunity(response.data.byCommunity);
        } else {
          console.error("Expected array but received:", response.data);
          setProjects([]);
        }
      } catch (error) {
        console.error("Error fetching projects by the same developer", error);
      }
    };

    fetchProjects();
  }, [projectDetails]);
  const { currencySign, } = useContext(UserContextCurrency);
  const convertCurrency = (price) => {
    switch (currencySign) {
      case 'EUR':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 0.25));
      case 'GBP':
      case 'GBP':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 0.22));
      case 'USD':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 0.27));
      case 'INR':
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, '')) * 22.35));
      default:
        return numberWithCommas(Math.round(parseFloat(price.replace(/,/g, ''))));
    }
  };

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://cityluxedxb.com/cityluxedxb/getProjectsdetails.php?id=${id}`);
      const data = response.data;

      // console.log('Project Details Data:', data);

      navigate(`/projects/single-project-details/${data.project.title}/${data.project.id}`, { state: { projectDetails: data } });
      setProjectDetails(data);

    } catch (error) {
      console.error('Error fetching project details:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleProjectClick = (id) => {
    fetchData(id);
  };
  const handleShowAllByDeveloper = () => {
    setDeveloperInfo(project[0].developer_name, 'developer');
    navigate('/projects/single-project-details/all-projects-by-filter');
  };

  const handleShowAllByCommunity = () => {
    setCommunityInfo(project[0].community, 'community');
    navigate('/projects/single-project-details/all-projects-by-filter');
  };
  const styles = {
    projectCardContainer: {
      display: { xs: "block", sm: "block", md: "flex" },
      justifyContent: "none !important"
    }
  }
  return (
    <>
      {/* other projects by same developer */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "15px",
        }}
      >
        <Heading title={"By Same Developer"} />
        <Button onClick={handleShowAllByDeveloper} sx={{
          fontSize: { xs: "12px", sm: "15px" },
          color: "#222",
          fontFamily: "'Nunito Sans', sans-serif",
          fontWeight: "600",
          padding: "12px",
          mt: "15px",
        }}>
          <Link
            component={Link}
            // to={`/projects/New Projects`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Show All
          </Link>
        </Button>
      </Box>
      {/* projects cards container */}
      <Box sx={styles.projectCardContainer}>
        {project.map((projects) => (
          <Grid item key={projects.id} onClick={() => handleProjectClick(projects.id)} sx={{ cursor: "pointer" }}>
            <Card sx={{ border: "1px solid #e1d3bc", boxShadow: "none", textDecoration: "none", maxWidth: "256px", minHeight: "444px", marginRight: "15px" }}>
              <CardMedia sx={{ height: "200px", textTransform: "uppercase", '&:hover': { transform: 'scale(1.2)', transition: 'transform 0.3s ease-in-out' } }} component="img" alt="" height="140" image={projects.file_name} />
              <CardContent>
                <Typography sx={{ height: "54px", fontSize: "18px", fontWeight: "100", mb: "3", textTransform: "uppercase", '&:hover': { color: "#e1d3bc" } }}>{projects.title}</Typography>
                <Box sx={{ display: "flex" }}>
                  <FaCircleArrowUp fill='#d7c3a8' style={{ marginRight: "5px", marginTop: "3px" }} />
                  <Typography sx={{ fontSize: "16px", fontWeight: "100", mb: "3" }}>  {currencySign}&nbsp;{convertCurrency(projects.price)}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "100", marginTop: "5px", marginBottom: "5px", textTransform: "uppercase" }}>LOCATION: {projects.location}</Typography>
                </Box>
                <Typography sx={{ fontSize: "12px", fontWeight: "100", marginBottom: "5px", textTransform: "uppercase" }}>Type: {projects.unit_type}</Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: "100", marginBottom: "5px", textTransform: "uppercase" }}>BY {projects.developer_name}</Typography>
                <Button variant="contained" size='small' color="primary" onClick={() => handleProjectClick(projects.id)} sx={{ marginTop: "15px", borderRadius: "0", width: "100%" }}>
                  Explore
                </Button>
              </CardContent>
            </Card>
          </Grid>

        ))}
      </Box>
      {/* other project in same community */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "15px",
        }}
      >
        <Heading title={"By Same Community"} />
        <Button
          sx={{ fontSize: { xs: "12px", sm: "15px" }, color: "#222", fontFamily: "'Nunito Sans', sans-serif", fontWeight: "600", padding: "12px", mt: "15px", }}
          onClick={handleShowAllByCommunity}>
          <Link
            component={Link}
            // to={`/projects/New Projects`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Show All
          </Link>
        </Button>
      </Box>
      {/* projects cards container */}
      <Box sx={styles.projectCardContainer}>
        {byCommunity.map((project) => (
          <Grid item key={project.id} onClick={() => handleProjectClick(project.id)} sx={{ cursor: "pointer" }}>
            <Card sx={{ border: "1px solid #e1d3bc", boxShadow: "none", textDecoration: "none", maxWidth: "256px", minHeight: "444px" }}>
              <CardMedia sx={{ height: "200px", textTransform: "uppercase", '&:hover': { transform: 'scale(1.2)', transition: 'transform 0.3s ease-in-out' } }} component="img" alt="" height="140" image={project.file_name} />
              <CardContent>
                <Typography sx={{ height: "54px", fontSize: "18px", fontWeight: "100", mb: "3", textTransform: "uppercase", '&:hover': { color: "#e1d3bc" } }}>{project.title}</Typography>
                <Box sx={{ display: "flex" }}>
                  <FaCircleArrowUp fill='#d7c3a8' style={{ marginRight: "5px", marginTop: "3px" }} />
                  <Typography sx={{ fontSize: "16px", fontWeight: "100", mb: "3" }}>  {currencySign}&nbsp;{convertCurrency(project.price)}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "100", marginTop: "5px", marginBottom: "5px", textTransform: "uppercase" }}>LOCATION: {project.location}</Typography>
                </Box>
                <Typography sx={{ fontSize: "12px", fontWeight: "100", marginBottom: "5px", textTransform: "uppercase" }}>Type: {project.unit_type}</Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: "100", marginBottom: "5px", textTransform: "uppercase" }}>BY {project.developer_name}</Typography>
                <Button variant="contained" size='small' color="primary" onClick={() => handleProjectClick(project.id)} sx={{ marginTop: "15px", borderRadius: "0", width: "100%" }}>
                  Explore
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Box>
    </>
  )
}

export default OtherProjectsBySameDeveloper