import { useState, useEffect } from "react";
import Client from "../../components/axios";
const useKeywordData = () => {
  var [projectsKeyword, setProjectsKeyword] = useState({
    project_unit_type: [],
    developer_name: [],
    project_title: [],
  });

  useEffect(() => {
    Client.post("https://cityluxedxb.com/cityluxedxb/input_search_project.php")
      .then((result) => {
        // console.log("Full API Response:", result); // Log full response for debugging/

        if (result.data.errorcode === "200") {
          projectsKeyword = result.data.result;

          // console.log("agfahgfdghasfdfsg:", projectsKeyword)

          // Check if projectsKeyword is defined
          if (projectsKeyword) {
            const {
              project_unit_type = [projectsKeyword.unit_type],
              developer_name = [projectsKeyword.ad_type],
              project_title = [projectsKeyword.property_name],
            } = projectsKeyword;

            // console.log("Projects Keyword: " + JSON.stringify(projectsKeyword));

            setProjectsKeyword({
              project_title,
              project_unit_type,
              developer_name,
            });
          } else {
            console.error(
              "projectsKeyword is undefined in the response:",
              result.data
            );
          }
        } else {
          console.error("Failed to fetch data:", result.data.message);
        }
      })
      .catch((error) => {
        console.error("API call failed", error);
      });
  }, []);

  return projectsKeyword;
};

export default useKeywordData;
